import React, { useEffect, useState } from 'react'
import "./banner.scss"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Information from '../Information/Information';
import Media from '../Media/Media';
import LatestTweet from '../LatestTweet/LatestTweet';
import Document from '../Documents/Document';
import { useTranslation } from 'react-i18next'

const Banner = () => {
    const { t } = useTranslation()
    const [timeshow, setTimeshow] = useState(false);
    const [day, setDay] = useState(0);
    const [hour, setHour] = useState(0);
    const [min, setMin] = useState(0);
    const [sec, setSec] = useState(0);
    const [day1, setDay1] = useState(0);
    const [hour1, setHour1] = useState(0);
    const [min1, setMin1] = useState(0);
    const [sec1, setSec1] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const time = 1721012400000;
            const diff = time - now;
            if (diff <= 0) {
                clearInterval(interval);
                setTimeshow(true);
                return;
            }
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const secs = Math.floor((diff % (1000 * 60)) / 1000);
            setDay(days);
            setHour(hours);
            setMin(mins);
            setSec(secs);
        }, 1000);
        return () => clearInterval(interval);
    }, []);
    useEffect(() => {
        const interval = setInterval(() => {
            const now = new Date().getTime();
            const time = 1723647600000;
            const diff = time - now;
            if (diff <= 0) {
                clearInterval(interval);
                setTimeshow(true);
                return;
            }
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const mins = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const secs = Math.floor((diff % (1000 * 60)) / 1000);
            setDay1(days);
            setHour1(hours);
            setMin1(mins);
            setSec1(secs);
        }, 1000);
        return () => clearInterval(interval);
    }, []);



    return (
        <>
            <section className="banner-gemsdetail">
                <img src="\assets\detail-page\banner-bg-inc.png" alt="img" className='img-fluid banner-bg' />
                <img src="\assets\detail-page\banner-bg-inc.png" alt="img" className='img-fluid d-none banner-bg-mbl' style={{objectFit: "fill"}} />
                <div className="custom-container h-100">
                    <div className="parent">
                        <div className="upper-content">
                            <div className="main-logo">
                                <img src="\assets\detail-page\banner-logo-inc.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="main-text">
                                <h4>Incentiv</h4>
                                <p>$INC</p>
                                <div className="social-icons">
                                    <a href="https://incentiv.net/" target='_blank'><img src="\assets\detail-page\social-icons\global.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://x.com/Incentiv_net" target='_blank'><img src="\assets\detail-page\social-icons\twitter.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://t.me/Incentiv_Announcments_Official" target='_blank'><img src="\assets\detail-page\social-icons\telegram.svg" alt="img" className='img-fluid' /></a>
                                    <a href="https://www.linkedin.com/company/incentiv-net/" target='_blank'><img src="\assets\detail-page\social-icons\linkedin.svg" alt="img" className='img-fluid' /></a>
                                    {/* <a href="#"><img src="\assets\detail-page\social-icons\discord.svg" alt="img" className='img-fluid' /></a>
                                    <a href="#"><img src="\assets\detail-page\social-icons\reddit.svg" alt="img" className='img-fluid' /></a> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="mid-content">
                            <div className="single-text">
                                <p>Token Price</p>
                                <h6>$0.07</h6>
                            </div>
                            <div className="single-text">
                                <p>Token Supply</p>
                                <h6>$20,000,000</h6>
                            </div>
                            <div className="single-text">
                                <p>Hard Cap per User</p>
                                <h6>1,500,000</h6>
                            </div>
                        </div> */}
                        <div className="gems-buy-presale d-none">
                            <div className="presale-box">
                                {/* {!timeshow ?
                                    <div className="upper-box">
                                        <h6>{t('Process-t13')}</h6>
                                        <div className="timer-div">
                                            <div className="inner">
                                                <h5>{day}</h5>
                                                <p>Days</p>
                                            </div>
                                            <span>:</span>
                                            <div className="inner">
                                                <h5>{hour}</h5>
                                                <p>Hours</p>
                                            </div>
                                            <span>:</span>
                                            <div className="inner">
                                                <h5>{min}</h5>
                                                <p>MINUTES</p>
                                            </div>
                                            <span>:</span>
                                            <div className="inner">
                                                <h5>{sec}</h5>
                                                <p>Seconds</p>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div className="upper-box">
                                        <h6>{t('Process-t10')}</h6>
                                        <div className="timer-div">
                                            <div className="inner">
                                                <h5>{day1}</h5>
                                                <p>Days</p>
                                            </div>
                                            <span>:</span>
                                            <div className="inner">
                                                <h5>{hour1}</h5>
                                                <p>Hours</p>
                                            </div>
                                            <span>:</span>
                                            <div className="inner">
                                                <h5>{min1}</h5>
                                                <p>MINUTES</p>
                                            </div>
                                            <span>:</span>
                                            <div className="inner">
                                                <h5>{sec1}</h5>
                                                <p>Seconds</p>
                                            </div>
                                        </div>
                                    </div>

                                } */}
                                <div className="bottom-box">
                                    <h6 className='top-head'>{t('Gems-detailt5')}</h6>
                                    <div className="mid-text">
                                        <div className="text">
                                            <p>{t('Gems-detailt6')}</p>
                                            {!timeshow ?
                                                <h6 style={{ color: "#F90" }}>{t('Coming')}</h6>
                                                :
                                                <h6 style={{ color: "#FE0000" }}>{t("Dop-detail3")}</h6>

                                            }
                                        </div>
                                        <div className="text">
                                            <p>{t('Gems-detailt7')}</p>
                                            <h6>--</h6>
                                        </div>
                                        {/* <div className="text">
                                        <p>{t('Gems-detailt8')}</p>
                                        <h6>--</h6>
                                    </div> */}
                                        <div className="text">
                                            <p>{t('Gems-detailt9')}</p>
                                            <h6>--</h6>
                                        </div>
                                    </div>
                                    <a href="https://privatesale.gems.vip/" target="_blank" className={!timeshow ? "btn-buy disablefornow" : "btn-buy disablefornow"}>{t('Btn-buynow')} <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                        <path d="M6.86309 2.02948V3.47413H8.30776V2.02948H6.86309ZM8.30776 3.47413V4.91809H9.75171V3.47413H8.30776ZM9.75171 4.91809V6.36274H0.831543V7.8046H9.75171V9.24854H11.1964V7.8046H12.9999V6.36274H11.1964V4.91809H9.75171ZM9.75171 9.24854H8.30776V10.6932H9.75171V9.24854ZM8.30776 10.6932H6.86309V12.1372H8.30776V10.6932Z" fill="black" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                        <ul class="nav nav-tabs banner-tabs" id="myTab" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">{t('Gems-detailt2')}</button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">{t('Gems-detailt3')}</button>
                            </li>
                            {/* <li class="nav-item" role="presentation">
                                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Latest Tweets</button>
                            </li> */}
                            <li class="nav-item" role="presentation">
                                <button class="nav-link" id="document-tab" data-bs-toggle="tab" data-bs-target="#document-tab-pane" type="button" role="tab" aria-controls="document-tab-pane" aria-selected="false">{t('Gems-detailt4')}</button>
                            </li>

                        </ul>
                    </div>
                </div>
            </section>



            <section className="bottom-new-section">
                <div className="parent">
                    <div className="left-side">
                        <div class="tab-content" id="myTabContent">
                            <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0"><Information /></div>
                            <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0"><Media /></div>
                            <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0"><LatestTweet /></div>
                            <div class="tab-pane fade" id="document-tab-pane" role="tabpanel" aria-labelledby="document-tab" tabindex="0"><Document /></div>
                        </div>
                    </div>
                    <div className="right-side displaynoneinmobile">
                        <div className="presale-box">
                            {/* {!timeshow ?
                                <div className="upper-box">
                                    <h6>{t('Process-t13')}</h6>
                                    <div className="timer-div">
                                        <div className="inner">
                                            <h5>{day}</h5>
                                            <p>Days</p>
                                        </div>
                                        <span>:</span>
                                        <div className="inner">
                                            <h5>{hour}</h5>
                                            <p>Hours</p>
                                        </div>
                                        <span>:</span>
                                        <div className="inner">
                                            <h5>{min}</h5>
                                            <p>MINUTES</p>
                                        </div>
                                        <span>:</span>
                                        <div className="inner">
                                            <h5>{sec}</h5>
                                            <p>Seconds</p>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="upper-box">
                                    <h6>{t('Process-t10')}</h6>
                                    <div className="timer-div">
                                        <div className="inner">
                                            <h5>{day1}</h5>
                                            <p>Days</p>
                                        </div>
                                        <span>:</span>
                                        <div className="inner">
                                            <h5>{hour1}</h5>
                                            <p>Hours</p>
                                        </div>
                                        <span>:</span>
                                        <div className="inner">
                                            <h5>{min1}</h5>
                                            <p>MINUTES</p>
                                        </div>
                                        <span>:</span>
                                        <div className="inner">
                                            <h5>{sec1}</h5>
                                            <p>Seconds</p>
                                        </div>
                                    </div>
                                </div>

                            } */}
                            <div className="bottom-box">
                                <h6 className='top-head'>{t('Gems-detailt5')}</h6>
                                <div className="mid-text">
                                    <div className="text">
                                        <p>{t('Gems-detailt6')}</p>
                                        {!timeshow ?
                                            <h6 style={{ color: "#F90" }}>{t('Coming')}</h6>
                                            :
                                            <h6 style={{ color: "#FE0000" }}>{t("Dop-detail3")}</h6>

                                        }
                                    </div>
                                    <div className="text">
                                        <p>{t('Gems-detailt7')}</p>
                                        <h6>--</h6>
                                    </div>
                                    {/* <div className="text">
                                        <p>{t('Gems-detailt8')}</p>
                                        <h6>--</h6>
                                    </div> */}
                                    <div className="text">
                                        <p>{t('Gems-detailt9')}</p>
                                        <h6>--</h6>
                                    </div>
                                </div>
                                {/* disablefornow */}
                                <a href="https://privatesale.gems.vip/" target="_blank" className={!timeshow ? "btn-buy disablefornow" : "btn-buy disablefornow"}>{t('Btn-buynow')} <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                    <path d="M6.86309 2.02948V3.47413H8.30776V2.02948H6.86309ZM8.30776 3.47413V4.91809H9.75171V3.47413H8.30776ZM9.75171 4.91809V6.36274H0.831543V7.8046H9.75171V9.24854H11.1964V7.8046H12.9999V6.36274H11.1964V4.91809H9.75171ZM9.75171 9.24854H8.30776V10.6932H9.75171V9.24854ZM8.30776 10.6932H6.86309V12.1372H8.30776V10.6932Z" fill="black" />
                                </svg></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Banner
