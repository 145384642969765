import React from 'react'
import "./twice.scss"
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Twice = () => {
    const { t } = useTranslation()

    return (
        <>
            <section className="twice-section">
            {/* <img src="\assets\landing\vector-grey.svg" alt="img" className='img-fluid vector-black' /> */}
                <div className="custom-container">
                    <div className="parent">
                        <div className="right-side">
                            <div className="wrapper-img">
                                <img src="\assets\landing\unlockproject.svg" alt="img" className='img-fluid' />
                            </div>
                        </div>
                        <div className="left-side">
                            <div className="main-content">
                                <div>
                                    <h6>{t('Twice-t1')}</h6>
                                    <p>{t('Twice-t2')}</p>
                                </div>
                                <div className="btn-transparent">
                                    <Link to="/signupforproject">{t('Twice-btn1')}</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="twice-section flex-direction-mobile" style={{ background: "#0C0C0C" }}>
                {/* <img src="\assets\landing\vector-black.svg" alt="img" className='img-fluid vector-black' /> */}
                <div className="custom-container">
                    <div className="parent">
                    <div className="left-side">
                            <div className="main-content">
                                <div>
                                <h6 style={{color: "#fff"}}>{t('Twice-t5')}</h6>
                                <p style={{color: "#AFAFAF"}}>{t('Twice-t6')}</p>
                                </div>
                                {/* <div className="btn-transparent">
                                    <Link to="/signupforproject">{t('Twice-btn1')}</Link>
                                </div> */}
                            </div>
                        </div>
                        <div className="right-side">
                            <div className="wrapper-img">
                                <img src="\assets\landing\funding.svg" alt="img" className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Twice
