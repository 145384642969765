
import React, { useEffect, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import Navbar from "../header/Navbar";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next'

const Banner = () => {
  const { t } = useTranslation()

  // const [showNav, setShowNav] = useState(true);

  // useEffect(() => {
  //   let timeoutId;

  //   const handleScroll = () => {
  //     setShowNav(true); 
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       setShowNav(false); 
  //     }, 3000);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     clearTimeout(timeoutId);
  //   };
  // }, []);
  return (
    <>
      <Navbar />
      <section className="main-banner">
        <div className="custom-container">
          <div className="main-content">
            <div className="main-logo d-none">
              <img src="\assets\landing\gems-log.svg" alt="img" className="img-fluid" />
            </div>
            <h4>{t('Banner-t1')}</h4>
            <p>{t('Banner-t2')}</p>
            <div className="twice-btns d-none">
              <a href="\whitepaper.pdf" target="_blank" className="btn-common">{t('Gems-detailt45')}</a>
              <a href="https://staking.gems.vip/" target="_blank" className="btn-black">{t('GEMS-Stake')}</a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
