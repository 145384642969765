import React from "react";
import "./document.scss";
import { useTranslation } from "react-i18next";

const Document = () => {
  const { t } = useTranslation();

  return (
    <>
      <section className="document-section">
        <div className="first-para" style={{ margin: "0" }}>
          <h2>
            {t("Gems-detailt4")} <span></span>
          </h2>
        </div>
        <div className="bottom-documents">
          <div className="single-document">
            <div className="wrap-img">
              <img
                src="\assets\detail-page\whitepaper.svg"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="text">
              <h6 style={{ marginBottom: "20px" }}>{t("Foundation")}</h6>
              <a href="\Inc-Foundataion-Paper(EN)-v1.1.pdf" target="_blank">
                {t("Btn-readnow")}{" "}
              </a>
            </div>
          </div>
          <div className="single-document">
            <div className="wrap-img">
              <img
                src="\assets\detail-page\whitepaper.svg"
                alt="img"
                className="img-fluid"
              />
            </div>
            <div className="text">
              <h6 style={{ marginBottom: "20px" }}>{t("Gems-detailt45")} v1.0</h6>
              <a href="\incentiv-whitepaper-en.pdf" target="_blank">
                {t("Btn-readnow")}{" "}
              </a>
            </div>
          </div>
          {/* <div className="single-document">
                        <div className="wrap-img">
                            <img src="\assets\detail-page\audit.svg" alt="img" className='img-fluid' />
                        </div>
                        <div className="text">
                            <h6>Audit Report</h6>
                            <p>Ut omnis suscipit aut exercitationem eius qui cumque ullam qui sunt nihil sit quam consequuntur non odio odio et corrupti velit.</p>
                            <a href="#">Read Now</a>
                        </div>
                    </div> */}
        </div>
      </section>
    </>
  );
};

export default Document;
