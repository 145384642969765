import "./navbar.scss";
import { useState } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";
import classNames from "classnames";

const languages = [
  {
    code: "en",
    name: "Eng",
    country_code: "gb",
  },
  {
    code: "jp",
    name: "Japanese",
    country_code: "jp",
  },
  {
    code: "es",
    name: "Spanish",
    country_code: "es",
  },
  {
    code: "tr",
    name: "Turkish",
    country_code: "tr",
  },
];
const GlobeIcon = ({ width = 24, height = 24 }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M3 12H8M3 12C3 16.9706 7.02944 21 12 21M3 12C3 7.02944 7.02944 3 12 3M8 12H16M8 12C8 16.9706 9.79086 21 12 21M8 12C8 7.02944 9.79086 3 12 3M16 12H21M16 12C16 7.02944 14.2091 3 12 3M16 12C16 16.9706 14.2091 21 12 21M21 12C21 7.02944 16.9706 3 12 3M21 12C21 16.9706 16.9706 21 12 21" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
);
const Navbar = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  var languageselected = localStorage.getItem("codelanguage");
  const currentLanguageCode = languageselected
    ? languageselected
    : cookies.get("i18next");
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();
  var languageactive = currentLanguage?.name;

  const changelanguage = (code) => {
    i18next.changeLanguage(code);
    localStorage.setItem("codelanguage", code);
  };
  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-xl">
           <div className="twice-nav">
           <Link class="navbar-brand" to="/"> <img src="\gems-landing-logo-new.svg" alt="img" className="img-fluid" /></Link>
           <div className="right-nav-new">
            <a style={{color: "#53FFEA"}}>Launchpad</a>
            <a style={{pointerEvents: "none"}} href="#">exchange <span className='comingsoon'>COMING SOON</span></a>
            <a style={{pointerEvents: "none"}} href="#">Academy <span className='comingsoon'>COMING SOON</span></a>
           </div>
           </div>
            <button class="navbar-toggler" type="button" onClick={handleShow1}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M6.25 21.25H16.25M6.25 15H23.75M13.75 8.75H23.75" stroke="#53FFEA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
              <div className="right-side-content">
                <div className="social-links">
                  <a href="https://twitter.com/Gems_VIP_" target="_blank"><img src="\assets\social-icons\twitter-n.svg" alt="img" className="img-fluid" /></a>
                  <a href="http://t.me/GemsVIPOfficial" target="_blank"><img src="\assets\social-icons\telegram.svg" alt="img" className="img-fluid" /></a>
                  <a href="https://www.linkedin.com/company/gemsvip/" target="_blank"><img src="\assets\social-icons\linkedin-n.svg" alt="img" className="img-fluid" /></a>
                  <a href="https://coinmarketcap.com/currencies/gems-vip/" target="_blank"><img src="\assets\social-icons\cmc.svg" alt="img" className="img-fluid" /></a>
                  <a href="https://www.coingecko.com/en/coins/gems-vip" target="_blank"><img src="\assets\social-icons\coingecko.svg" alt="img" className="img-fluid" /></a>
                </div>
                <a href="\whitepaper.pdf" target="_blank" className="whitepaper-btn"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                  <path d="M17.6688 6.39375L12.8563 1.58125C12.7188 1.44375 12.5813 1.375 12.375 1.375H5.5C4.74375 1.375 4.125 1.99375 4.125 2.75V19.25C4.125 20.0063 4.74375 20.625 5.5 20.625H16.5C17.2563 20.625 17.875 20.0063 17.875 19.25V6.875C17.875 6.66875 17.8063 6.53125 17.6688 6.39375ZM12.375 3.025L16.225 6.875H12.375V3.025ZM16.5 19.25H5.5V2.75H11V6.875C11 7.63125 11.6187 8.25 12.375 8.25H16.5V19.25Z" fill="white" />
                  <path d="M6.875 15.125H15.125V16.5H6.875V15.125ZM6.875 11H15.125V12.375H6.875V11Z" fill="white" />
                </svg>{t('Gems-detailt45')}</a>
                <div className="dropdown lang-drop">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="twice-elem">
                      <GlobeIcon />
                      <h6>{languageactive}</h6>
                    </div>
                    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
                      <path d="M5.53636 0H2.86648H0.462309C0.0508998 0 -0.154805 0.607131 0.13661 0.963036L2.35651 3.67419C2.7122 4.1086 3.29075 4.1086 3.64645 3.67419L4.49069 2.64311L5.86634 0.963036C6.15347 0.607131 5.94777 0 5.53636 0Z" fill="white" />
                    </svg>
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <span className="dropdown-item-text">{t("Languages")}</span>
                    </li>
                    {languages.map(({ code, name, country_code }) => (
                      <li key={country_code}>
                        <a
                          className={classNames("dropdown-item", {
                            disabled: currentLanguageCode === code,
                          })}
                          onClick={() => {
                            changelanguage(code);
                          }}
                        >
                          <span
                            className={`flag-icon flag-icon-${country_code} mx-2`}
                          ></span>
                          {name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </div>
      </section>

      <Offcanvas
        className="nav-offcanvas"
        placement="end"
        show={show}
        onHide={handleClose}
      >
        <Offcanvas.Body>
          <div className="parent-grid">
            <div className="left-side-grid">
              <Link to="/">
                <img
                  src="\mini-logo-grey.svg"
                  alt="img"
                  className="img-fluid"
                />
              </Link>
            </div>
            <div className="mid-side-grid">
              <div className="upper-grid">
                <img
                  src="\gems-logo.svg"
                  alt="img"
                  className="img-fluid gems-logo"
                />
                <a href="https://leaders.gems.vip/" target="_blank">
                  {t("Sign-in")}
                </a>
              </div>
              <div className="bottom-grid">
                <div className="single-grid">
                  <div className="inner-top">{/* <h6>Features</h6> */}</div>
                  <div className="inner-bottom">
                    <p>© 2024 GEMS</p>
                  </div>
                </div>
                <div className="single-grid">
                  <div className="inner-top">{/* <h6>About</h6> */}</div>
                  <div className="inner-bottom">
                    <Link to="/signupforproject">{t("Where-btn1")}</Link>
                  </div>
                </div>
                <div className="single-grid">
                  <div className="inner-top">{/* <h6>Projects</h6> */}</div>
                  <div className="inner-bottom">
                    {/* <Link to="/signupforleader">Sign up as Leader</Link> */}
                  </div>
                </div>
                <div className="single-grid">
                  <div className="inner-top">{/* <h6>How it Works</h6> */}</div>
                  <div className="inner-bottom">
                    <div className="social-icon">
                      <h6>{t("Join")}</h6>
                      <div className="inner">
                        <a href="https://twitter.com/Gems_VIP_" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                          >
                            <path
                              d="M0.0316953 0.5L5.05085 7.67027L0 13.5H1.13674L5.55875 8.39598L9.13162 13.5H13L7.69845 5.92642L12.3997 0.5H11.263L7.19054 5.2007L3.90008 0.5H0.0316953ZM1.70336 1.39462H3.48051L11.3281 12.6052H9.55093L1.70336 1.39462Z"
                              fill="#AEAEAE"
                            />
                          </svg>
                        </a>
                        <a href="http://t.me/GemsVIPOfficial" target="_blank">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="14"
                            viewBox="0 0 13 14"
                            fill="none"
                          >
                            <path
                              d="M0.893682 6.23547C4.38334 4.68313 6.71032 3.65973 7.87463 3.16527C11.199 1.7535 11.8897 1.50826 12.34 1.50008C12.439 1.49838 12.6604 1.52344 12.8038 1.64226C12.9249 1.7426 12.9583 1.87813 12.9742 1.97325C12.9901 2.06838 13.01 2.28508 12.9942 2.4544C12.8141 4.38701 12.0346 9.07694 11.638 11.2415C11.4702 12.1574 11.1398 12.4645 10.8199 12.4945C10.1248 12.5599 9.59692 12.0255 8.92363 11.5749C7.87007 10.8697 7.27488 10.4308 6.25221 9.74269C5.07034 8.94748 5.8365 8.51043 6.51004 7.79615C6.68631 7.60923 9.74916 4.76477 9.80844 4.50673C9.81586 4.47446 9.82274 4.35417 9.75275 4.29065C9.68275 4.22713 9.57945 4.24885 9.5049 4.26612C9.39923 4.29061 7.71615 5.42645 4.45566 7.67363C3.97792 8.00858 3.54521 8.17177 3.1575 8.16322C2.73009 8.15379 1.90793 7.91648 1.29673 7.71363C0.547079 7.46482 -0.0487324 7.33328 0.00314937 6.91073C0.0301726 6.69064 0.327017 6.46555 0.893682 6.23547Z"
                              fill="#AEAEAE"
                            />
                          </svg>
                        </a>
                        <a
                          href="https://www.linkedin.com/company/gemsvip/"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="13"
                            viewBox="0 0 14 13"
                            fill="none"
                          >
                            <g clip-path="url(#clip0_315_274)">
                              <path
                                d="M11.9583 0.125C12.3341 0.125 12.6944 0.274255 12.9601 0.539932C13.2257 0.805609 13.375 1.16594 13.375 1.54167V11.4583C13.375 11.8341 13.2257 12.1944 12.9601 12.4601C12.6944 12.7257 12.3341 12.875 11.9583 12.875H2.04167C1.66594 12.875 1.30561 12.7257 1.03993 12.4601C0.774255 12.1944 0.625 11.8341 0.625 11.4583V1.54167C0.625 1.16594 0.774255 0.805609 1.03993 0.539932C1.30561 0.274255 1.66594 0.125 2.04167 0.125H11.9583ZM11.6042 11.1042V7.35C11.6042 6.73757 11.3609 6.15023 10.9278 5.71717C10.4948 5.28412 9.90743 5.04083 9.295 5.04083C8.69292 5.04083 7.99167 5.40917 7.65167 5.96167V5.17542H5.67542V11.1042H7.65167V7.61208C7.65167 7.06667 8.09083 6.62042 8.63625 6.62042C8.89926 6.62042 9.15149 6.7249 9.33746 6.91087C9.52344 7.09684 9.62792 7.34908 9.62792 7.61208V11.1042H11.6042ZM3.37333 4.06333C3.68894 4.06333 3.99162 3.93796 4.21479 3.71479C4.43796 3.49162 4.56333 3.18894 4.56333 2.87333C4.56333 2.21458 4.03208 1.67625 3.37333 1.67625C3.05585 1.67625 2.75136 1.80237 2.52687 2.02687C2.30237 2.25136 2.17625 2.55585 2.17625 2.87333C2.17625 3.53208 2.71458 4.06333 3.37333 4.06333ZM4.35792 11.1042V5.17542H2.39583V11.1042H4.35792Z"
                                fill="#878787"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_315_274">
                                <rect
                                  width="13"
                                  height="13"
                                  fill="white"
                                  transform="translate(0.5)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-side-grid">
              <a onClick={handleClose}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M18 18L12 12M12 12L6 6M12 12L18 6M12 12L6 18"
                    stroke="#0B0B0B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </a>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <Offcanvas
        className="nav-offcanvas-mobile"
        placement="top"
        show={show1}
        onHide={handleClose1}
      >
        <Offcanvas.Body>
          <div className="main-header">
            <a href="/">
              <img src="\gems-landing-logo.svg" alt="img" className="img-fluid" />
            </a>
            <a onClick={handleClose1}>
              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                <path d="M20.9662 10.3595L10.3596 20.9661C9.9972 21.3285 9.39615 21.3285 9.03376 20.9661C8.67137 20.6037 8.67137 20.0027 9.03376 19.6403L19.6404 9.03371C20.0028 8.67132 20.6038 8.67132 20.9662 9.03371C21.3286 9.3961 21.3286 9.99714 20.9662 10.3595Z" fill="#53FFEA" />
                <path d="M20.9662 20.9663C20.6038 21.3287 20.0028 21.3287 19.6404 20.9663L9.03376 10.3597C8.67137 9.9973 8.67137 9.39626 9.03376 9.03386C9.39615 8.67147 9.9972 8.67147 10.3596 9.03386L20.9662 19.6405C21.3286 20.0029 21.3286 20.6039 20.9662 20.9663Z" fill="#53FFEA" />
              </svg>
            </a>
          </div>
          <div className="bottom-body">
            <div className="upper-links">
            <a style={{color: "#53FFEA"}}>Launchpad</a>
            <a style={{pointerEvents: "none"}} href="#">exchange <span className='comingsoon'>COMING SOON</span></a>
            <a style={{pointerEvents: "none"}} href="#">Academy <span className='comingsoon'>COMING SOON</span></a>
              <Link to="/signupforproject">{t('Where-btn1')}</Link>
              {/* <a href="#">Sign In as a Leader</a> */}
              <Link to="/apply">{t('Applyfor')} {t('Countryman')}</Link>
              <Link to="/faqs">{t('Faqs')}</Link>
            </div>
            <div className="twice-btns">
              <a href="\whitepaper.pdf" target="_blank" className="whitepaper-btn"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" fill="none">
                <path d="M17.6688 6.39375L12.8563 1.58125C12.7188 1.44375 12.5813 1.375 12.375 1.375H5.5C4.74375 1.375 4.125 1.99375 4.125 2.75V19.25C4.125 20.0063 4.74375 20.625 5.5 20.625H16.5C17.2563 20.625 17.875 20.0063 17.875 19.25V6.875C17.875 6.66875 17.8063 6.53125 17.6688 6.39375ZM12.375 3.025L16.225 6.875H12.375V3.025ZM16.5 19.25H5.5V2.75H11V6.875C11 7.63125 11.6187 8.25 12.375 8.25H16.5V19.25Z" fill="white" />
                <path d="M6.875 15.125H15.125V16.5H6.875V15.125ZM6.875 11H15.125V12.375H6.875V11Z" fill="white" />
              </svg>{t('Gems-detailt45')}</a>
              <div className="dropdown lang-drop">
                <button
                  className="dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <div className="twice-elem">
                    <GlobeIcon />
                    <h6>{languageactive}</h6>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="6" height="4" viewBox="0 0 6 4" fill="none">
                    <path d="M5.53636 0H2.86648H0.462309C0.0508998 0 -0.154805 0.607131 0.13661 0.963036L2.35651 3.67419C2.7122 4.1086 3.29075 4.1086 3.64645 3.67419L4.49069 2.64311L5.86634 0.963036C6.15347 0.607131 5.94777 0 5.53636 0Z" fill="white" />
                  </svg>
                </button>
                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  <li>
                    <span className="dropdown-item-text">{t("Languages")}</span>
                  </li>
                  {languages.map(({ code, name, country_code }) => (
                    <li key={country_code}>
                      <a
                        className={classNames("dropdown-item", {
                          disabled: currentLanguageCode === code,
                        })}
                        onClick={() => {
                          changelanguage(code);
                        }}
                      >
                        <span
                          className={`flag-icon flag-icon-${country_code} mx-2`}
                        ></span>
                        {name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="social-links">
            <a href="https://twitter.com/Gems_VIP_" target="_blank"><img src="\assets\social-icons\twitter-n.svg" alt="img" className="img-fluid" /></a>
                  <a href="http://t.me/GemsVIPOfficial" target="_blank"><img src="\assets\social-icons\telegram.svg" alt="img" className="img-fluid" /></a>
                  <a href="https://www.linkedin.com/company/gemsvip/" target="_blank"><img src="\assets\social-icons\linkedin-n.svg" alt="img" className="img-fluid" /></a>
                  <a href="https://coinmarketcap.com/currencies/gems-vip/" target="_blank"><img src="\assets\social-icons\cmc.svg" alt="img" className="img-fluid" /></a>
                  <a href="https://www.coingecko.com/en/coins/gems-vip" target="_blank"><img src="\assets\social-icons\coingecko.svg" alt="img" className="img-fluid" /></a>
            </div>
          </div>

        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Navbar;
