import React, { useEffect, useState } from "react";
import "./faqs.scss";
import Banner from "../landing/main-banner/Banner";
import Accordion from "react-bootstrap/Accordion";
import BannerGems from "../landing/main-banner/BannerGems";
import { useTranslation } from "react-i18next";

const Faqs = () => {
  const [activeKey, setActiveKey] = useState("0");
  const handleSelect = (key) => {
    setActiveKey(key);
  };
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <section className="mainfaqs">
        <BannerGems />
        <div className="innerfaq">
          <Accordion activeKey={activeKey} onSelect={handleSelect}>
            <Accordion.Item
              eventKey="0"
              className={activeKey === "0" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>01.</span>{t('Faq-q1')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
               {t('Faq-a1')}
                </p>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item
              eventKey="1"
              className={activeKey === "1" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>02. {t('Faq-q2')}</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                {t('Faq-a2')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="2"
              className={activeKey === "2" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>03.</span>{t('Faq-q3')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                {t('Faq-a3')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="3"
              className={activeKey === "3" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>04.</span>{t('Faq-q4')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                {t('Faq-a4')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="4"
              className={activeKey === "4" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>05.</span>{t('Faq-q5')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                {t('Faq-a5')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="5"
              className={activeKey === "5" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>06.</span>{t('Faq-q6')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                {t('Faq-a6')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              eventKey="6"
              className={activeKey === "6" ? "active" : ""}
            >
              <Accordion.Header>
                <p>
                  <span>07.</span>{t('Faq-q7')}
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <p className="para">
                {t('Faq-a7')}
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>
    </>
  );
};

export default Faqs;
