import { useCallback } from "react";
import useWeb3 from "../useWeb3";
import Environment from "../../utils/Environment";
import { getGemsTokenContract } from "../../utils/contractHelpers";

export const GetBalance = () => {
  const web3 = useWeb3();
  const tokenAddress = Environment.GemsToken;
  const lockingAddress = Environment.GemsLocking;

  const contract = getGemsTokenContract(tokenAddress, web3);
  const GetBal = useCallback(async () => {
    const approved = await contract.methods.balanceOf(lockingAddress).call();
    const locked = approved / 10 ** 18;
    return locked;
  }, [contract]);

  return { GetBal: GetBal };
};

export default GetBalance;
