import React, { useEffect, useState } from "react";
import "./signupproject.scss";
import Navbar1 from "../landing/header/Navbar1";
import { Modal } from "react-bootstrap";
import { Api_Url } from "../../utils/ApiUrl";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import BannerApply from "./BannerApply";

const Signupproject = () => {
  const { t } = useTranslation();

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);
  const [loader, setLoader] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [selectedCompanySize, setSelectedCompanySize] = useState(
    t("Signupforproject-t4")
  );
  const [companyError, setCompanyError] = useState("");
  const [nameError, setNameError] = useState("");
  const [twitter, setTwitter] = useState("");
  const [twitterError, setTwitterError] = useState("");
  const [telegram, setTelegram] = useState("");
  const [telegramError, setTelegramError] = useState("");
  const [email, setEmail] = useState("");
  const [emailerrorregister, setEmailErrorRegister] = useState("");
  const [projectIndustry, setProjectIndustry] = useState("");
  const [projectIndustryError, setProjectIndustryError] = useState("");
  const [website, setWebsite] = useState("");
  const [websiteError, setWebsiteError] = useState("");
  const [name, setname] = useState("");
  const [numberError, setNumberError] = useState("");
  const [position, setPosition] = useState("");
  const [positionError, setPositionError] = useState("");

  const handleItemClick = (size) => {
    setSelectedCompanySize(size);
  };

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  // Function to validate social media links
  // const isValidSocialLink = (link, type) => {
  //   let regex;
  //   if (type === "twitter") {
  //     regex = /^(?:https?:\/\/)?(?:www\.)?x\.com\/([\w\-]+)(?:\/|$)/i;
  //   }
    
  //   else if (type === "telegram") {
  //     regex = /^(?:https?:\/\/)?t\.me\/(?:\w)*$/i;
  //   } else if (type === "website") {
  //     regex =
  //       /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(?:\/\S*)?$/i;
  //   }
  //   return regex.test(link);
  // };
  const isValidSocialLink = (link, type) => {
    let regex;
  
    if (type === "twitter") {
      // Updated Twitter regex to match x.com links and others correctly.
      regex = /^(?:https?:\/\/)?(?:www\.)?(?:x|twitter)\.com\/([\w\-]+)(?:\/|$)/i;
    } 
    else if (type === "telegram") {
      // Updated Telegram regex to match valid t.me links and more variations.
      regex = /^(?:https?:\/\/)?(?:www\.)?t\.me\/[\w\-]+(?:\/\S*)?$/i;
    } 
    else if (type === "website") {
      // Updated Website regex to match standard domain names and paths.
      regex = /^(?:https?:\/\/)?(?:www\.)?([a-zA-Z0-9-]+)\.([a-zA-Z]{2,})(?:\/\S*)?$/i;
    }
  
    return regex.test(link);
  };
  
  const submit = (e) => {
    e.preventDefault();
  
    // Validation logic
    if (email.length === 0) {
      setEmailErrorRegister(t("Signupforproject-e1"));
    } else if (!isValidEmail(email)) {
      setEmailErrorRegister(t("Signupforproject-e2"));
    }
  
    if (projectName.length === 0) {
      setNameError(t("Signupforproject-e3"));
    }
  
    if (website.length === 0) {
      setWebsiteError(t("Signupforproject-e4"));
    } else if (!isValidSocialLink(website, "website")) {
      setWebsiteError(t("Signupforproject-e5"));
    }
  
    if (name.length === 0) {
      setNumberError(t("Signupforproject-e6"));
    }
  
    if (selectedCompanySize === "Company Size") {
      setCompanyError(t("Signupforproject-e7"));
    }
  
    if (position.length === 0) {
      setPositionError(t("Signupforproject-e8"));
    }
  
    if (projectIndustry.length === 0) {
      setProjectIndustryError(t("Signupforproject-e9"));
    }
  
    if (twitter.length === 0) {
      setTwitterError(t("Signupforproject-e10"));
    } else if (!isValidSocialLink(twitter, "twitter")) {
      console.log(twitter)

      setTwitterError(t("Signupforproject-e11"));
    }
  
    if (telegram.length === 0) {
      setTelegramError(t("Signupforproject-e12"));
    } else if (!isValidSocialLink(telegram, "telegram")) {
      setTelegramError(t("Signupforproject-e13"));
    } else {
      // If validation passed, prepare data for submission
      var data = JSON.stringify({
        projectName: projectName,
        twitterLink: twitter,
        telegramLink: telegram,
        companySize: selectedCompanySize,
        projectIndustry: projectIndustry,
        website: website,
        projectCreatorPosition: position,
        projectCreatorName: name,
        email: email,
      });
  
      setLoader(true);
  
      var config = {
        method: "post",
        url: `${Api_Url}/temp/creator-signup`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
  
      axios(config)
        .then(function (response) {
          handleShow1(); // Assuming this is a success handler
          setLoader(false);
  
          // Reset fields and errors after success
          setProjectName('')
          setEmail('');
          setWebsite('');
          setWebsite('');
          setname('');
          setPosition('');
          setProjectIndustry('');
          setEmailErrorRegister('')
          setTwitter('');
          setTelegram('');
          setSelectedCompanySize("Company Size");
          setProjectIndustry('')
          // Reset error messages
          setEmailErrorRegister('');
          setNameError('');
          setWebsiteError('');
          setNumberError('');
          setCompanyError('');
          setPositionError('');
          setProjectIndustryError('');
          setTwitterError('');
          setTelegramError('');
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.message);
          setLoader(false);
        });
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className="wrapper-apply">
        <BannerApply />
        <section className="apply-section sectionforsignupproject">
          <div className="custom-container">
            <div className="parent">
              <div className="left-side">
                <div className="main-content">
                  <h4>{t('Unlockwallet')} </h4>
                  <p className="para">{t('UNclockpara')} </p>
                  <div className="inner-twice">
                    <div className="twice-data">
                      <img src="\assets\signup\community.svg" alt="img" className="img-fluid" />
                      <div className="text">
                        <h5>{t('COmmunitydriven')}</h5>
                        <p>{t('Leveragepara')}</p>
                      </div>
                    </div>
                    <div className="twice-data">
                      <img src="\assets\signup\easysetup.svg" alt="img" className="img-fluid" />
                      <div className="text">
                        <h5>{t('Easysetup')}</h5>
                        <p>{t('Effortpara')}</p>
                      </div>
                    </div>
                    <div className="twice-data">
                      <img src="\assets\signup\proven.svg" alt="img" className="img-fluid" />
                      <div className="text">
                        <h5>{t('Proven')}</h5>
                        <p>{t('Ensurepara')}</p>
                      </div>
                    </div>
                    <div className="twice-data">
                      <img src="\assets\signup\customize.svg" alt="img" className="img-fluid" />
                      <div className="text">
                        <h5>{t('Customizable')}</h5>
                        <p>{t('Rewardpara')}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div className="apply-box">
                  <h6 className="main-head">
                  {t('Fillinthe')} <span>{t('Detail')}</span>
                  </h6>
                  <p className="para">{t('PleasefillPara')}</p>
                  <div className="bottom-content">
                    <div className="option-field">
                      <input
                        value={projectName}
                        onChange={(e) => (
                          setProjectName(e.target.value), setNameError("")
                        )}
                        type="text"
                        placeholder={t("Signupforproject-t3")}
                      />
                      {nameError ? (
                        <p className="error-message">{nameError}</p>
                      ) : null}
                    </div>
                    <div className="option-field">
                      <div className="dropdown">
                        <button
                          className="dropdown-toggle"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {selectedCompanySize === "Company Size" ? (
                            <>{selectedCompanySize}</>
                          ) : (
                            <h6 style={{ color: "#000" }}>{selectedCompanySize}</h6>
                          )}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16 10L12 14L8 10"
                              stroke="#0B0B0B"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                        {companyError ? (
                          <p className="error-message">{companyError}</p>
                        ) : null}
                        <ul className="dropdown-menu">
                          <li>
                            <p
                              className="dropdown-item"
                              href="#"
                              onClick={() => (
                                handleItemClick("1-10"), setCompanyError("")
                              )}
                            >
                              1-10
                            </p>
                          </li>
                          <li>
                            <p
                              className="dropdown-item"
                              href="#"
                              onClick={() => (
                                handleItemClick("10-50"), setCompanyError("")
                              )}
                            >
                              10-50
                            </p>
                          </li>
                          <li>
                            <p
                              className="dropdown-item"
                              href="#"
                              onClick={() => (
                                handleItemClick("50-100"), setCompanyError("")
                              )}
                            >
                              50-100
                            </p>
                          </li>
                          <li>
                            <p
                              className="dropdown-item"
                              href="#"
                              onClick={() => (
                                handleItemClick("100+"), setCompanyError("")
                              )}
                            >
                              above 100
                            </p>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="option-field">
                      <input
                        value={projectIndustry}
                        onChange={(e) => (
                          setProjectIndustry(e.target.value),
                          setProjectIndustryError("")
                        )}
                        type="text"
                        placeholder={t("Signupforproject-t5")}
                      />
                      {projectIndustryError ? (
                        <p className="error-message">{projectIndustryError}</p>
                      ) : null}
                    </div>
                    <div className="option-field">
                      <input
                        value={website}
                        onChange={(e) => (
                          setWebsite(e.target.value), setWebsiteError("")
                        )}
                        type="text"
                        placeholder={t("Signupforproject-t6")}
                      />
                      {websiteError ? (
                        <p className="error-message">{websiteError}</p>
                      ) : null}
                    </div>
                    <div className="option-field">
                      <input
                        value={twitter}
                        onChange={(e) => (
                          setTwitter(e.target.value), setTwitterError("")
                        )}
                        type="text"
                        placeholder={t("Signupforproject-t7")}
                      />
                      {twitterError ? (
                        <p className="error-message">{twitterError}</p>
                      ) : null}
                    </div>
                    <div className="option-field">
                      <input
                        value={telegram}
                        onChange={(e) => (
                          setTelegram(e.target.value), setTelegramError("")
                        )}
                        type="text"
                        placeholder={t("Signupforproject-t8")}
                      />
                      {telegramError ? (
                        <p className="error-message">{telegramError}</p>
                      ) : null}
                    </div>
                    <div className="twice-fields">
                      <div className="option-field">
                        <input
                          value={name}
                          onChange={(e) => (
                            setname(e.target.value), setNumberError("")
                          )}
                          type="text"
                          placeholder={t("Signupforproject-t9")}
                        />
                        {numberError ? (
                          <p className="error-message">{numberError}</p>
                        ) : null}
                      </div>
                      <div className="option-field">
                        <input
                          value={position}
                          onChange={(e) => (
                            setPosition(e.target.value), setPositionError("")
                          )}
                          type="text"
                          placeholder={t("Signupforproject-t10")}
                        />
                        {positionError ? (
                          <p className="error-message">{positionError}</p>
                        ) : null}
                      </div>
                    </div>
                    <div className="option-field">
                      <input
                        value={email}
                        onChange={(e) => (
                          setEmail(e.target.value), setEmailErrorRegister("")
                        )}
                        type="text"
                        placeholder={t("Signupforproject-t11")}
                      />
                      {emailerrorregister ? (
                        <p className="error-message">{emailerrorregister}</p>
                      ) : null}
                    </div>
                  </div>

                  <div onClick={submit} className="btn-sign">
                    {loader ? (
                      <a href="#" className="btn-continue">
                        <img
                          src="\assets\loadingp.png"
                          alt="img"
                          className="img-fluid loading"
                        />
                        {t("Signupforproject-t13")}
                      </a>
                    ) : (
                      <a className="btn-continue">{t("Signupforproject-t12")}</a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <Modal
        className="walletconnectmodal addteammembermodal"
        show={show1}
        onHide={handleClose1}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Signupforproject-t14")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="addedmodal">
            <img src="\assets\tick.svg" alt="addedimg" className="addedimg" />
            <h6 className="addedhead">{t("Signupforproject-t15")}</h6>
            <button className="greybtn w-100" onClick={() => handleClose1()}>
              {t("Signupforproject-t16")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Signupproject;
