import React from 'react'
import "./era.scss"
import { useTranslation } from 'react-i18next'

const Era = () => {
    const { t } = useTranslation()

    return (
        <>
            <section className="new-era">
                {/* <img src="\assets\shadows\newera-top-shadow.svg" alt="img" className='img-fluid newera-top-shadow' /> */}
                {/* <img src="\assets\shadows\newera-top-shadow-mobilee.png" alt="img" className='img-fluid newera-top-shadow-mobile d-none' /> */}
                <div className="custom-container">
                    <div className="main-heading">
                        <h6>{t('Era-t1')}</h6>
                        <p>{t('Era-t2')}</p>
                    </div>
                    <div className="parent-cards">
                        <div className="single-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                                <path d="M56.6667 66.6666C56.6667 61.1438 49.2047 56.6666 40 56.6666C30.7953 56.6666 23.3333 61.1438 23.3333 66.6666M70 56.6679C70 52.5672 65.8863 49.0431 60 47.5M10 56.6679C10 52.5672 14.1137 49.0431 20 47.5M60 34.1203C62.0458 32.2892 63.3333 29.6283 63.3333 26.6666C63.3333 21.1438 58.8562 16.6666 53.3333 16.6666C50.7722 16.6666 48.4359 17.6295 46.6667 19.2129M20 34.1203C17.9542 32.2892 16.6667 29.6283 16.6667 26.6666C16.6667 21.1438 21.1438 16.6666 26.6667 16.6666C29.2278 16.6666 31.5641 17.6295 33.3333 19.2129M40 46.6666C34.4772 46.6666 30 42.1895 30 36.6666C30 31.1438 34.4772 26.6666 40 26.6666C45.5228 26.6666 50 31.1438 50 36.6666C50 42.1895 45.5228 46.6666 40 46.6666Z" stroke="#53FFEA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h6>{t('Era-t3')}</h6>
                            <p>{t('Era-t4')}</p>
                        </div>
                        <div className="single-card set-border-inner">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                                <path d="M49.9999 30L36.6666 43.3333L29.9999 36.6667M66.6666 33.8835C66.6666 55.7777 50.1064 65.5935 43.0859 68.7713L43.077 68.7752C42.3386 69.1094 41.9685 69.2769 41.1295 69.421C40.5981 69.5122 39.4051 69.5122 38.8737 69.421C38.0313 69.2763 37.6573 69.1082 36.9131 68.7713C29.8926 65.5936 13.3333 55.7777 13.3333 33.8835V20.6673C13.3333 16.9336 13.3333 15.0654 14.0599 13.6393C14.699 12.3849 15.7182 11.3658 16.9726 10.7266C18.3987 10 20.2669 10 24.0006 10H56.0006C59.7343 10 61.5985 10 63.0246 10.7266C64.279 11.3658 65.3015 12.3849 65.9407 13.6393C66.6666 15.064 66.6666 16.93 66.6666 20.6564V33.8835Z" stroke="#53FFEA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h6>{t('Era-t5')}</h6>
                            <p>{t('Era-t6')}</p>
                        </div>
                        <div className="single-card">
                            <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                                <path d="M66.6681 23.3334L47.1809 43.125C46.8309 43.4805 46.654 43.6586 46.4973 43.7988C43.9661 46.0644 40.1386 46.0644 37.6073 43.7989C37.4506 43.6586 37.2724 43.4807 36.9222 43.125C36.572 42.7694 36.3968 42.5915 36.2402 42.4512C33.7089 40.1856 29.8798 40.1856 27.3486 42.4512C27.1923 42.5911 27.0177 42.7685 26.6692 43.1224L13.3333 56.6667M66.6681 23.3334L66.6666 43.3334M66.6681 23.3334H46.6666" stroke="#53FFEA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            <h6>{t('Era-t7')}</h6>
                            <p>{t('Era-t8')}</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Era
