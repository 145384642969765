import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Navbar from "../landing/header/Navbar";
import "./bannerapply.scss"

const BannerApply = ({ scrollToSecondSection }) => {
  const { t } = useTranslation();

  // const [showNav, setShowNav] = useState(true);

  // useEffect(() => {
  //   let timeoutId;

  //   const handleScroll = () => {
  //     setShowNav(true);
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       setShowNav(false);
  //     }, 3000);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //     clearTimeout(timeoutId);
  //   };
  // }, []);
  return (
    <>
      <Navbar />
        <section className="main-bannernew">
          <img
            src="https://res.cloudinary.com/drt6vurtt/image/upload/v1709191268/gems/banner-bg_kk3bqp.svg"
            alt="img"
            className="img-fluid banner-bg"
          />
          <div className="custom-container">
            <div className="main-contentss">
              <div className="faqsheads">
                <h3>
                  <span>{t('Signupas')} </span> <br />
                  {t('Process-t2')}
                </h3>
              </div>
            </div>
          </div>
        </section>
    </>
  );
};

export default BannerApply;
