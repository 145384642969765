import React, { useEffect, useState } from "react";
import "./stats.scss";
import { useTranslation } from "react-i18next";
import GetBalance from "../../../hooks/DataFetchers/getBalance";
import Environment from "../../../utils/Environment";
import { getGemsStakingContract } from "../../../utils/contractHelpers";
import useWeb3 from "../../../hooks/useWeb3";

const Stats = ({ gemsData }) => {
  const web3 = useWeb3();

  const { GetBal } = GetBalance();

  const { t } = useTranslation();
  const [lockedAmount, setLockedAmount] = useState("0");
  const [stakedAmount, setStakedAmount] = useState("0");

  var rewardPerYear = 6428571;
  var apy = (rewardPerYear / stakedAmount) * 100;

  const getGemsStakedAmount = async () => {
    // console.log("total");
    const contractAddress = Environment?.GemsStaking;
    const contract = getGemsStakingContract(contractAddress, web3);
    try {
      const approved = await contract.methods.totalStaked().call();

      setStakedAmount(approved / 10 ** 18);
    } catch (error) {
      console.log("error get");
    }
  };

  useEffect(() => {
    getGemsStakedAmount();
    getGemsLockedAmount();
  }, []);
  const getGemsLockedAmount = async () => {
    const staked = await GetBal();
    setLockedAmount(staked);
  };

  return (
    <>
      <section className="gems-token-stats">
        <img src="\assets\landing\stats-shadow.png" alt="img" className="img-fluid stats-shadow" />
        <div className="custom-container">
          <div className="main-heading">
            <h6>{t("Gems-token-stat")}</h6>
          </div>
          <div className="bottom-content">
            <div className="gradientborder">
              <div className="single-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M12.5 1V23"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M17.5 5H10C9.07174 5 8.1815 5.36875 7.52513 6.02513C6.86875 6.6815 6.5 7.57174 6.5 8.5C6.5 9.42826 6.86875 10.3185 7.52513 10.9749C8.1815 11.6313 9.07174 12 10 12H15C15.9283 12 16.8185 12.3687 17.4749 13.0251C18.1313 13.6815 18.5 14.5717 18.5 15.5C18.5 16.4283 18.1313 17.3185 17.4749 17.9749C16.8185 18.6313 15.9283 19 15 19H6.5"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <h5>${gemsData?.current_price?.toFixed(4)}</h5>
                <p>{t("Gems-detailt7")}</p>
              </div>
            </div>
            <div className="gradientborder">
              <div className="single-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M19.5 11H5.5C4.39543 11 3.5 11.8954 3.5 13V20C3.5 21.1046 4.39543 22 5.5 22H19.5C20.6046 22 21.5 21.1046 21.5 20V13C21.5 11.8954 20.6046 11 19.5 11Z"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.5 11V7C7.5 5.67392 8.02678 4.40215 8.96447 3.46447C9.90215 2.52678 11.1739 2 12.5 2C13.8261 2 15.0979 2.52678 16.0355 3.46447C16.9732 4.40215 17.5 5.67392 17.5 7V11"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {/* <h5>{parseInt(lockedAmount)?.toLocaleString()}</h5> */}
                <h5>15,232,142</h5>
                <p>{t("Token-lock")}</p>
              </div>
            </div>
            <div className="gradientborder">
              <div className="single-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M12.5 8C17.4706 8 21.5 6.65685 21.5 5C21.5 3.34315 17.4706 2 12.5 2C7.52944 2 3.5 3.34315 3.5 5C3.5 6.65685 7.52944 8 12.5 8Z"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M21.5 12C21.5 13.66 17.5 15 12.5 15C7.5 15 3.5 13.66 3.5 12"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.5 5V19C3.5 20.66 7.5 22 12.5 22C17.5 22 21.5 20.66 21.5 19V5"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <h5>{parseInt(stakedAmount)?.toLocaleString()}</h5>
                <p>{t("Token-stake")}</p>
              </div>
            </div>
            <div className="gradientborder">
              <div className="single-card">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                >
                  <path
                    d="M8.5 12H16.5"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <circle cx="12.5" cy="16" r="1" fill="#53FFEA" />
                  <circle cx="12.5" cy="8" r="1" fill="#53FFEA" />
                  <path
                    d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z"
                    stroke="#53FFEA"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {stakedAmount > 0 ? <h5>{apy?.toFixed(2)}%</h5> : <h5>0%</h5>}

                <p>{t("Stakingapy")}</p>
              </div>
            </div>
          </div>
          <div className="bottom-twice-btns">
            <div class="dropdown">
              <button
                class="dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("Buy")} $gems
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                >
                  <path
                    d="M9 4L6 7L3 4"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <ul class="dropdown-menu">
              <li>
                  <a
                    class="dropdown-item"
                    href="https://www.gate.io/trade/GEMS_USDT"
                    target="_blank"
                  >
                    <img
                      src="\assets\landing\exchnages\gateio.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <img
                      src="\assets\landing\exchnages\arrow-link.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://www.mexc.com/exchange/GEMS_USDT?_from=market"
                    target="_blank"
                  >
                    <img
                      src="\assets\landing\exchnages\mexc-updated.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <img
                      src="\assets\landing\exchnages\arrow-link.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://www.bitget.com/spot/GEMSUSDT?type=spot"
                    target="_blank"
                  >
                    <img
                      src="\assets\landing\exchnages\bitget-updated.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <img
                      src="\assets\landing\exchnages\arrow-link.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://www.bitmart.com/trade/en-US?symbol=%24GEMS_USDT"
                    target="_blank"
                  >
                    <img
                      src="\assets\landing\exchnages\bitmart.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <img
                      src="\assets\landing\exchnages\arrow-link.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://bingx.com/en/spot/GEMSUSDT/"
                    target="_blank"
                  >
                    <img
                      src="\assets\landing\exchnages\bingx.svg"
                      alt="img"
                      className="img-fluid"
                    />
                    <img
                      src="\assets\landing\exchnages\arrow-link.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                </li>
                {/* <li><a class="dropdown-item" href="#">
                                                <img src="\assets\detail-page\exchanges\kucoin.svg" alt="img" className='img-fluid' />
                                                <img src="\assets\landing\exchnages\arrow-link.svg" alt="img" className='img-fluid' />
                                            </a></li> */}
              </ul>
            </div>
            <a
              href="https://staking.gems.vip/"
              target="_blank"
              className="btn-stake"
            >
              {t("GEMS-Stake")}
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stats;
