import React from 'react'
import "./info.scss"
import { Player } from "video-react";
import { useTranslation } from 'react-i18next'

const Information = () => {
  const { t } = useTranslation()

    return (
        <>
            <section className="information-section">
                <div className="first-para" style={{margin: "0"}}>
                    <h2>{t('Gems-detailt11')} <span></span></h2>
                    <p>{t('Gems-detailt12')}</p>
                </div>
                <div className="img-wrapper">
                    <img src="\assets\detail-page\projectinfo1.png" alt="img" className='img-fluid' />
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt13')}<span></span></h2>
                    <p>{t('Gems-detailt14')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt15')} <span></span></h2>
                    <p>{t('Gems-detailt16')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt17')}<span></span></h2>
                    <p>{t('Gems-detailt18')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt19')} <span></span></h2>
                    <p>{t('Gems-detailt20')}</p>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt21')} <span></span></h2>
                    <p>{t('Gems-detailt22')}</p>
                    <p>{t('Gems-detailt23')}</p>
                </div>
                {/* <div className="video-part">
                    <Player
                        playsInline
                        poster="\assets\detail-page\videoframe.png"
                        src=""
                    />
                     <img src="\assets\detail-page\videoframe.png" alt="img" className='img-fluid' />
                </div> */}
                {/* <div className="first-para">
                    <p>Hum enim dolorem sit voluptate voluptatem a optio quos hic dicta dolore aut quia galisum eum eius libero. Nam atque quia ut autem fugit hic consequatur illum qui consequatur eveniet in galisum aliquam. In accusamus fuga quo iusto Quis sit aliquam rerum.</p>
                    <ul>
                        <li>Hum enim dolorem sit voluptate voluptatem a optio quos hic dicta dolore aut quia galisum eum eius libero. Nam atque quia ut autem fugit hic consequatur illum qui consequatur.</li>
                        <li>Qui atque voluptatem cum commodi unde aut culpa commodi et molestias libero id vero ducimus aut omnis sunt. Et repellat sunt aut perspiciatis expedita est quos obcaecati.</li>
                        <li>Nam explicabo Quis non unde alias sed voluptate possimus! Id vitae dolorum sed neque asperiores At impedit quia aut pariatur voluptas id omnis esse ut incidunt quod.</li>
                        <li>Hum enim dolorem sit voluptate voluptatem a optio quos hic dicta dolore aut quia galisum eum eius libero. Nam atque quia ut autem fugit hic consequatur illum qui consequatur.</li>
                        <li>Qui atque voluptatem cum commodi unde aut culpa commodi et molestias libero id vero ducimus aut omnis sunt. Et repellat sunt aut perspiciatis expedita est quos obcaecati.</li>
                        <li>Nam explicabo Quis non unde alias sed voluptate possimus! Id vitae dolorum sed neque asperiores At impedit quia aut pariatur voluptas id omnis esse ut incidunt quod.</li>
                    </ul>
                </div> */}
                <div className="first-para">
                    <h2>{t('Gems-detailt24')} <span></span></h2>
                    <p>{t('Gems-detailt25')}</p>
                    <ul>
                        <li>{t('Gems-detailt26')}</li>
                        <li>{t('Gems-detailt27')}</li>
                    </ul>
                    <p>{t('Gems-detailt28')}</p>
                    <ul>
                        <li>{t('Gems-detailt29')}</li>
                        <li>{t('Gems-detailt30')}</li>
                    </ul>
                    <p>{t('Gems-detailt31')}</p>
                    <ul>
                        <li>{t('Gems-detailt32')}</li>
                        <li>{t('Gems-detailt33')}</li>
                    </ul>
                </div>
                <div className="first-para">
                    <h2>{t('Gems-detailt34')} <span></span></h2>
                    {/* <p>Lorem ipsum dolor sit amet. Aut voluptatem explicabo aut fuga impedit nam nesciunt nesciunt ut quisquam recusandae. Ea porro voluptatem sed expedita earum ut labore architecto qui earum corporis.</p> */}
                    <div className="team-gems">
                    <div className="single-team">
                            <img src="\assets\gemsteam\isaacjoshua.svg" alt="img" className='img-fluid' />
                            <h6>Isaac Joshua, ceo</h6>
                            <p>Over 7 years of experience in blockchain, Web3 & crypto, including notable roles at eToro, a respected crypto brokerage firm, and a publicly traded crypto investment group</p>
                        </div>
                        
                        {/* <div className="single-team">
                            <img src="\assets\gemsteam\GEMS-6664.JPG" alt="img" className='img-fluid' />
                            <h6>{t('Gems-detailt37')}</h6>
                            <p>{t('Gems-detailt38')}</p>
                        </div> */}
                        <div className="single-team">
                            <img src="\assets\gemsteam\IMG_6866-Edit.JPG" alt="img" className='img-fluid' />
                            <h6>{t('Gems-detailt39')}</h6>
                            <p>{t('Gems-detailt40')}</p>
                        </div>
                        <div className="single-team">
                            <img src="\assets\gemsteam\GEMS-6748.JPG" alt="img" className='img-fluid' />
                            <h6>{t('Gems-detailt41')}</h6>
                            <p>{t('Gems-detailt42')}</p>
                        </div>
                    </div>

                </div>
                {/* <div className="first-para">
                    <h2>Who are the partners of Gems? <span></span></h2>
                    <p>Gems collaborates with cutting-edge technology companies that are bringing innovative solutions to the forefront. These companies undergo a thorough vetting and evaluation process by Gems to ensure that we present our leaders and investors with only the projects we identify as true Gems.</p>
                    <div className="partners-content">
                        <img src="\assets\detail-page\partner\1.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\2.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\3.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\4.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\5.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\6.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\7.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\8.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\9.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\10.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\11.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\12.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\13.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\14.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\15.svg" alt="img" className='img-fluid' />
                        <img src="\assets\detail-page\partner\16.svg" alt="img" className='img-fluid' />
                    </div>
                </div> */}
                <div className="first-para">
                    <h2>{t('Gems-detailt43')} <span></span></h2>
                    {/* <p>Est omnis velit et enim quia et molestiae dolorem in impedit temporibus. Non numquam odit in consequatur fugit ea dolor doloremque sit modi numquam. Aut dolores delectus qui minima quod aut maiores repellendus et expedita quaerat. Et ducimus praesentium ea internos natus ut provident totam.</p> */}
                    <div className="twice-tokenomics">
                        <div className="tokenomic-img">
                            <img src="\assets\detail-page\gems-tokenmoics.png" alt="img" className='img-fluid' />
                        </div>
                        {/* <div className="right-content">
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#009482" />
                                </svg>Early Contributors</h6>
                                <p>20%</p>
                            </div>
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#00AE99" />
                                </svg>Investors</h6>
                                <p>14%</p>
                            </div>
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#00C9B1" />
                                </svg>Mysten Labs Treasury</h6>
                                <p>10%</p>
                            </div>
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#00E4C8" />
                                </svg>Public Sale</h6>
                                <p>6%</p>
                            </div>
                            <div className="text">
                                <h6><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                    <circle cx="6" cy="6" r="6" fill="#53FFEA" />
                                </svg>Community Reserve</h6>
                                <p>50%</p>
                            </div>
                        </div> */}
                    </div>

                </div>
            </section>
        </>
    )
}

export default Information
