import React, { useEffect, useState } from "react";
import "./banner.scss";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Information from "../Information/Information";
import Media from "../Media/Media";
import LatestTweet from "../LatestTweet/LatestTweet";
import Document from "../Documents/Document";
import { useTranslation } from "react-i18next";

const Banner = ({ gemsData }) => {
  const { t } = useTranslation();

  var now11 = new Date();
  let createdDate = now11?.getTime();
  let currentTime = createdDate / 1000;
  currentTime = parseInt(currentTime);
  const [currentRount, setCurrentRount] = useState(null);
  const [timeshow, setTimeshow] = useState(false);
  const [day, setDay] = useState(0);
  const [hour, setHour] = useState(0);
  const [min, setMin] = useState(0);
  const [sec, setSec] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      let now = "";
      let time = "";
      let diff = "";
      if (currentTime < new Date(parseInt(currentRount?.startTime))) {
        now = new Date();
        time = new Date(parseInt(currentRount?.startTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false);
      } else if (
        currentTime > new Date(parseInt(currentRount?.startTime)) &&
        currentTime < new Date(parseInt(currentRount?.endTime))
      ) {
        now = new Date();
        time = new Date(parseInt(currentRount?.endTime));
        diff = time.getTime() - parseInt(now.getTime() / 1000);
        setTimeshow(false);
        // console.log(diff);
      }
      if (diff <= 0) {
        if (currentTime < new Date(parseInt(currentRount?.startTime))) {
          window.location.reload();
        }

        setTimeshow(true);

        clearInterval(interval);
      }
      const days = Math.floor(diff / (60 * 60 * 24));
      const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
      const mins = Math.floor((diff % (60 * 60)) / 60);
      const secs = Math.floor(diff % 60);
      setDay(days);
      setHour(hours);
      setMin(mins);
      setSec(secs);
    }, 1000);
    return () => clearInterval(interval);
  }, [currentRount]);

  return (
    <>
      <section className="banner-gemsdetail banner-gemsdetailforgems">
        <img
          src="\assets\detail-page\banner-bg.png"
          alt="img"
          className="img-fluid banner-bg"
        />
        <img
          src="\assets\detail-page\banner-bg-mbl.png"
          alt="img"
          className="img-fluid d-none banner-bg-mbl"
        />
        <div className="custom-container h-100">
          <div className="parent">
            <div className="upper-content">
              <div className="main-logo">
                <img
                  src="\assets\detail-page\banner-logo.svg"
                  alt="img"
                  className="img-fluid"
                />
              </div>
              <div className="main-text">
                <h4>{t("Gems-detailt1")}</h4>
                <p>$GEMS</p>
                <div className="social-icons">
                  <a href="https://gems.vip/" target="_blank">
                    <img
                      src="\assets\detail-page\social-icons\global.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                  <a href="https://twitter.com/Gems_VIP_" target="_blank">
                    <img
                      src="\assets\detail-page\social-icons\twitter.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                  <a href="http://t.me/GemsVIPOfficial" target="_blank">
                    <img
                      src="\assets\detail-page\social-icons\telegram.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/gemsvip/"
                    target="_blank"
                  >
                    <img
                      src="\assets\detail-page\social-icons\linkedin.svg"
                      alt="img"
                      className="img-fluid"
                    />
                  </a>
                  {/* <a href="#"><img src="\assets\detail-page\social-icons\discord.svg" alt="img" className='img-fluid' /></a>
                                    <a href="#"><img src="\assets\detail-page\social-icons\reddit.svg" alt="img" className='img-fluid' /></a> */}
                </div>
              </div>
            </div>
            {/* <div className="mid-content">
                            <div className="single-text">
                                <p>Token Price</p>
                                <h6>$0.07</h6>
                            </div>
                            <div className="single-text">
                                <p>Token Supply</p>
                                <h6>$20,000,000</h6>
                            </div>
                            <div className="single-text">
                                <p>Hard Cap per User</p>
                                <h6>1,500,000</h6>
                            </div>
                        </div> */}
            <div className="gems-buy-presale d-none">
              <div className="presale-box">
                <div className="bottom-box">
                  <h6 className="top-head">{t("Gems-detailt5")}</h6>
                  <div className="mid-text">
                    <div className="text">
                      <p>{t("Gems-detailt6")}</p>
                      <h6 style={{ color: "#FE0000" }}>{t("Dop-detail3")}</h6>
                    </div>
                    <div className="text">
                      <p>{t("Gems-detailt7")}</p>
                      <h6>--</h6>
                    </div>
                    <div className="text">
                      <p>{t("Gems-detailt8")}</p>
                      <h6>20,000,000 USD</h6>
                    </div>
                    <div className="text">
                      <p>{t("Gems-detailt9")}</p>
                      <h6>4/4</h6>
                    </div>
                  </div>
                  <a
                    href="https://claim.gems.vip/"
                    target="_blank"
                    className="btn-buy"
                  >
                    {t("claimgems")}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M6.86309 2.02948V3.47413H8.30776V2.02948H6.86309ZM8.30776 3.47413V4.91809H9.75171V3.47413H8.30776ZM9.75171 4.91809V6.36274H0.831543V7.8046H9.75171V9.24854H11.1964V7.8046H12.9999V6.36274H11.1964V4.91809H9.75171ZM9.75171 9.24854H8.30776V10.6932H9.75171V9.24854ZM8.30776 10.6932H6.86309V12.1372H8.30776V10.6932Z"
                        fill="black"
                      />
                    </svg>
                  </a>
                  <div class="dropdown btn-buy-gems">
                    <button
                      class="dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {t("Buy")}{" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="12"
                        height="12"
                        viewBox="0 0 12 12"
                        fill="none"
                      >
                        <path
                          d="M9 4L6 7L3 4"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </button>
                    <ul class="dropdown-menu">
                    <li>
                      <a
                        class="dropdown-item"
                        href="https://www.gate.io/trade/GEMS_USDT"
                        target="_blank"
                      >

                        <img
                          src="\assets\landing\exchnages\gateioblack.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\assets\detail-page\exchanges\redirect-arrow.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="https://www.mexc.com/exchange/GEMS_USDT?_from=market"
                          target="_blank"
                        >
                          <img
                            src="\assets\detail-page\exchanges\mexc-updated.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <img
                            src="\assets\detail-page\exchanges\redirect-arrow.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="https://www.bitget.com/spot/GEMSUSDT?type=spot"
                          target="_blank"
                        >
                          <img
                            src="\assets\detail-page\exchanges\bitget-updated.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <img
                            src="\assets\detail-page\exchanges\redirect-arrow.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="https://www.bitmart.com/trade/en-US?symbol=%24GEMS_USDT"
                          target="_blank"
                        >
                          <img
                            src="\assets\landing\exchnages\bitmart-white.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <img
                            src="\assets\detail-page\exchanges\redirect-arrow.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="https://bingx.com/en/spot/GEMSUSDT/"
                          target="_blank"
                        >
                          <img
                            src="\assets\landing\exchnages\bingx-white.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <img
                            src="\assets\detail-page\exchanges\redirect-arrow.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                  
                      {/* <li>
                        <a
                          class="dropdown-item"
                          href="https://app.uniswap.org/explore/tokens/ethereum/0x3010ccb5419f1ef26d40a7cd3f0d707a0fa127dc"
                          target="_blank"
                        >
                          <img
                            src="\assets\detail-page\exchanges\uniswap.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <img
                            src="\assets\detail-page\exchanges\redirect-arrow.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </li> */}
                      {/* <li><a class="dropdown-item" href="#">
                                                <img src="\assets\detail-page\exchanges\kucoin.svg" alt="img" className='img-fluid' />
                                                <img src="\assets\detail-page\exchanges\redirect-arrow.svg" alt="img" className='img-fluid' />
                                            </a></li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="token-detail-gems-dark">
                <h5 className="main-heading">{t("TokenDetail")}</h5>
                <div className="bottom-detail">
                  <div className="text">
                    <p>{t("Gems-detailt7")}</p>
                    <h6>
                      ${gemsData?.current_price?.toFixed(4)}{" "}
                      {gemsData?.price_change_percentage_24h < 0 ? (
                        <span className="red">
                          {gemsData?.price_change_percentage_24h}%
                        </span>
                      ) : (
                        <span className="green">
                          {gemsData?.price_change_percentage_24h}%
                        </span>
                      )}
                    </h6>
                  </div>
                  {/* <div className="text">
                                        <p>{t("Marketcap")}</p>
                                        <h6>
                                            $ {gemsData?.market_cap?.toLocaleString()}{" "}
                                            {gemsData?.market_cap_change_percentage_24h < 0 ? (
                                                <span className="red">
                                                    {gemsData?.market_cap_change_percentage_24h}%
                                                </span>
                                            ) : (
                                                <span className="green">
                                                    {gemsData?.market_cap_change_percentage_24h}%
                                                </span>
                                            )}
                                        </h6>
                                    </div> */}
                  <div className="text">
                    <p>{t("Volume")} (24h)</p>
                    <h6>${gemsData?.total_volume?.toLocaleString()}</h6>
                  </div>
                  <div className="text">
                    <p>{t("Totalsupply")}</p>
                    <h6>{gemsData?.total_supply?.toLocaleString()}</h6>
                  </div>
                  <div className="text">
                    <p>{t("Max-supply")}</p>
                    <h6>{gemsData?.total_supply?.toLocaleString()}</h6>
                  </div>
                </div>
              </div>
            </div>
            <ul class="nav nav-tabs banner-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link active"
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  {t("Gems-detailt2")}
                </button>
              </li>
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  {t("Gems-detailt3")}
                </button>
              </li>
              {/* <li class="nav-item" role="presentation">
                                <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Latest Tweets</button>
                            </li> */}
              <li class="nav-item" role="presentation">
                <button
                  class="nav-link"
                  id="document-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#document-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="document-tab-pane"
                  aria-selected="false"
                >
                  {t("Gems-detailt4")}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <section className="bottom-new-section">
        <div className="parent">
          <div className="left-side">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home-tab-pane"
                role="tabpanel"
                aria-labelledby="home-tab"
                tabindex="0"
              >
                <Information />
              </div>
              <div
                class="tab-pane fade"
                id="profile-tab-pane"
                role="tabpanel"
                aria-labelledby="profile-tab"
                tabindex="0"
              >
                <Media />
              </div>
              <div
                class="tab-pane fade"
                id="contact-tab-pane"
                role="tabpanel"
                aria-labelledby="contact-tab"
                tabindex="0"
              >
                <LatestTweet />
              </div>
              <div
                class="tab-pane fade"
                id="document-tab-pane"
                role="tabpanel"
                aria-labelledby="document-tab"
                tabindex="0"
              >
                <Document />
              </div>
            </div>
          </div>
          <div className="right-side displaynoneinmobile">
            <div className="presale-box">
              <div className="bottom-box">
                <h6 className="top-head">{t("Gems-detailt5")}</h6>
                <div className="mid-text">
                  <div className="text">
                    <p>{t("Gems-detailt6")}</p>
                    <h6 style={{ color: "#FE0000" }}>{t("Dop-detail3")}</h6>
                  </div>
                  <div className="text">
                    <p>{t("Gems-detailt7")}</p>
                    <h6>--</h6>
                  </div>
                  <div className="text">
                    <p>{t("Gems-detailt8")}</p>
                    <h6>20,000,000 USD</h6>
                  </div>
                  <div className="text">
                    <p>{t("Gems-detailt9")}</p>
                    <h6>4/4</h6>
                  </div>
                </div>
                {/* disablefornow */}
                <a
                  href="https://claim.gems.vip/"
                  target="_blank"
                  className="btn-buy"
                >
                  {t("claimgems")}{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M6.86309 2.02948V3.47413H8.30776V2.02948H6.86309ZM8.30776 3.47413V4.91809H9.75171V3.47413H8.30776ZM9.75171 4.91809V6.36274H0.831543V7.8046H9.75171V9.24854H11.1964V7.8046H12.9999V6.36274H11.1964V4.91809H9.75171ZM9.75171 9.24854H8.30776V10.6932H9.75171V9.24854ZM8.30776 10.6932H6.86309V12.1372H8.30776V10.6932Z"
                      fill="black"
                    />
                  </svg>
                </a>
                <div class="dropdown btn-buy-gems">
                  <button
                    class="dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {t("Buy")}{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                      fill="none"
                    >
                      <path
                        d="M9 4L6 7L3 4"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  <ul class="dropdown-menu">
                  <li>
                      <a
                        class="dropdown-item"
                        href="https://www.gate.io/trade/GEMS_USDT"
                        target="_blank"
                      >
                        <img
                          src="\assets\landing\exchnages\gateioblack.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\assets\detail-page\exchanges\redirect-arrow.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="https://www.mexc.com/exchange/GEMS_USDT?_from=market"
                        target="_blank"
                      >
                        <img
                          src="\assets\detail-page\exchanges\mexc-updated.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\assets\detail-page\exchanges\redirect-arrow.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        href="https://www.bitget.com/spot/GEMSUSDT?type=spot"
                        target="_blank"
                      >
                        <img
                          src="\assets\detail-page\exchanges\bitget-updated.svg"
                          alt="img"
                          className="img-fluid"
                        />
                        <img
                          src="\assets\detail-page\exchanges\redirect-arrow.svg"
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                    </li>
                    <li>
                        <a
                          class="dropdown-item"
                          href="https://www.bitmart.com/trade/en-US?symbol=%24GEMS_USDT"
                          target="_blank"
                        >
                          <img
                            src="\assets\landing\exchnages\bitmart-white.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <img
                            src="\assets\detail-page\exchanges\redirect-arrow.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          class="dropdown-item"
                          href="https://bingx.com/en/spot/GEMSUSDT/"
                          target="_blank"
                        >
                          <img
                            src="\assets\landing\exchnages\bingx-white.svg"
                            alt="img"
                            className="img-fluid"
                          />
                          <img
                            src="\assets\detail-page\exchanges\redirect-arrow.svg"
                            alt="img"
                            className="img-fluid"
                          />
                        </a>
                      </li>
             
                    {/* <li><a class="dropdown-item" href="#">
                                            <img src="\assets\detail-page\exchanges\kucoin.svg" alt="img" className='img-fluid' />
                                            <img src="\assets\detail-page\exchanges\redirect-arrow.svg" alt="img" className='img-fluid' />
                                        </a></li> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="token-detail-gems">
              <h5 className="main-heading">{t("TokenDetail")}</h5>
              <div className="bottom-detail">
                <div className="text">
                  <p>{t("Gems-detailt7")}</p>
                  <h6>
                    ${gemsData?.current_price?.toFixed(4)}{" "}
                    {gemsData?.price_change_percentage_24h < 0 ? (
                      <span className="red">
                        {gemsData?.price_change_percentage_24h}%
                      </span>
                    ) : (
                      <span className="green">
                        {gemsData?.price_change_percentage_24h}%
                      </span>
                    )}
                  </h6>
                </div>
                {/* <div className="text">
                                    <p>{t("Marketcap")}</p>
                                    <h6>
                                        $ {gemsData?.market_cap?.toLocaleString()}{" "}
                                        {gemsData?.market_cap_change_percentage_24h < 0 ? (
                                            <span className="red">
                                                {gemsData?.market_cap_change_percentage_24h}%
                                            </span>
                                        ) : (
                                            <span className="green">
                                                {gemsData?.market_cap_change_percentage_24h}%
                                            </span>
                                        )}
                                    </h6>
                                </div> */}
                <div className="text">
                  <p>{t("Volume")} (24h)</p>
                  <h6>
                    $ {gemsData?.total_volume?.toLocaleString()}
                    {/* {gemsData?.market_cap_change_percentage_24h<0?<span className="red">{gemsData?.market_cap_change_percentage_24h}%</span>:<span className="green">{gemsData?.market_cap_change_percentage_24h}%</span>} */}
                  </h6>
                </div>
                <div className="text">
                  <p>{t("Totalsupply")}</p>
                  <h6>{gemsData?.total_supply?.toLocaleString()} GEMS</h6>
                </div>
                <div className="text">
                  <p>{t("Max-supply")}</p>
                  <h6>{gemsData?.total_supply?.toLocaleString()} GEMS</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
