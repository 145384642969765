import React, { useEffect, useState } from "react";
import "./App.scss";
import Landing from "./components/landing/Landing.js";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/landing/footer/Footer.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Signupproject from "./components/Signupproject/Signupproject.js";
import Signupleader from "./components/Signupproject/Signupleader.js";
import GemsDetailPage from "./components/GemsDetailPage/GemsDetailPage.js";
import DopDetailPage from "./components/DopDetailPage/DopDetailPage.js";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import axios from "axios";
import Faqs from "./components/Faqs/Faqs.js";
import Apply from "./components/Apply/Apply.js";
import IncentivDetailPage from "./components/IncentivDetailPage/IncentiveDetailPage.js";
import Insurance from "./components/Insurance/Insurance.js";
import Terms from "./components/Terms/Terms.js";
import Privacy from "./components/Privacy/Privacy.js";
import ApplyForMarketing from "./components/ApplyForMarketing/Apply.js";
import Career from "./components/Career/Career.js";
import Education from "./components/Education/Education.js";
import Blog from "./components/Blog/Blog.js";
import BlogDetail from "./components/Blog/BlogDetail.js";
import LeiaDetailPage from "./components/LeiaDetailPage/LeiaDetailPage.js";
import OlympusDetailPage from "./components/OlympusDetailPage/OlympusDetailPage.js";
import Aml from "./components/Privacy/AmlPolicy.js";

function App() {

  const [gemsData, setGemsData] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // Function to fetch the price
    const fetchPrice = async () => {
      try {
        const response = await axios.get(
          "https://pro-api.coingecko.com/api/v3/coins/markets",
          {
            headers: {
              "X-CG-Pro-API-Key": "CG-AMT6oz8K7Qpx7qESamW9hKZu",
            },
            params: {
              ids: "gems-vip", // Replace with the correct CoinGecko ID for GEMS token
              vs_currency: "usd",
            },
          }
        );
        if (response?.data?.length > 0) {
          setGemsData(response?.data[0]);
        }
      } catch (err) {
        console.error(err);
      }
    };

    // Fetch the price when the component mounts
    fetchPrice();

    // Optionally, set up an interval to fetch the price every X seconds
    // const intervalId = setInterval(fetchPrice, 60000);

    // Clean up the interval when the component unmounts
    // return () => clearInterval(intervalId);
  }, []);




  return (
    <>
      <ToastContainer style={{ fontSize: 20 }} />
      <Router>
        {/* <Navbar/> */}
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Landing gemsData={gemsData} />}
          />
          <Route
            exact
            path="/gemsdetail"
            render={() => <GemsDetailPage gemsData={gemsData} />}
          />
          <Route exact path="/dopdetail" component={DopDetailPage} />
          <Route exact path="/incentiv" component={IncentivDetailPage} />
          <Route exact path="/leia" component={LeiaDetailPage} />
          <Route exact path="/olympus" component={OlympusDetailPage} />
          <Route exact path="/signupforproject" component={Signupproject} />
          <Route exact path="/signupforleader" component={Signupleader} />
          <Route exact path="/faqs" component={Faqs} />
          {/* <Route exact path="/applyforcountrymanager" component={Apply} /> */}
          <Route exact path="/insurance" component={Insurance} />
          <Route exact path="/terms" component={Terms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/amlpolicy" component={Aml} />
          <Route exact path="/careers" component={Career} />
          <Route
            exact
            path="/applyformarketingmanager"
            component={ApplyForMarketing}
          />
          <Route exact path="/education" component={Education} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:slug" component={BlogDetail} />
        </Switch>
        <Footer />
      </Router>

      {/* // white paper updated */}

     
    </>
  );
}

export default App;
