import React, { useEffect } from 'react'
import Banner from './Banner/Banner'
import Navbar from '../landing/header/Navbar'
import { Link } from 'react-router-dom'

const GemsDetailPage = ({gemsData}) => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <Navbar />
            <Banner gemsData={gemsData} />


        </>
    )
}

export default GemsDetailPage
