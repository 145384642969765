import React, { useState } from 'react'
import "./media.scss"
import { useTranslation } from 'react-i18next'
import { Modal } from 'react-bootstrap'

const Media = () => {
  const { t } = useTranslation()

  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const handleShow = (imageUrl) => {
    setSelectedImage(imageUrl);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const images = [
    'assets/detail-page/dopcommunity/card1.png',
    'assets/detail-page/dopcommunity/card2.png',
    'assets/detail-page/dopcommunity/card3.png',
    'assets/detail-page/dopcommunity/card4.png',
    'assets/detail-page/dopcommunity/card5.png',
  ];

  return (
    <>
      <section className="media-section">
        <div className="first-para" style={{ margin: "0" }}>
          <h2>{t('Gems-detailt44')}<span></span></h2>
        </div>
        <div className="bottom-gallery">
          <a href="https://www.youtube.com/watch?v=Y824iRhfnsU" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\videolibrary\1.png" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=GJe9BWqaBsw" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\videolibrary\2.png" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=S3tS3Bnnt34" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\videolibrary\3.png" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=3ClvwCUyv7Y" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\videolibrary\4.png" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=3QgR5dnVyB0" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\5.jpg" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=3ClvwCUyv7Y&ab_channel=DataOwnershipProtocol" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\6.jpg" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=K_fzym4Jc18" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\1.jpeg" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=MBX2yEP-MfQ" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\2.jpeg" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=9wuCramt0h8" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\3.jpeg" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          <a href="https://www.youtube.com/watch?v=bG-q-8EM_bU" target='_blank'>
            <div className="single-gallery">
              <img src="\assets\detail-page\dopcommunity\4.jpeg" alt="img" className='img-fluid' />
              <img src="\play-btn.svg" alt="img" className='img-fluid play-btn' />
            </div>
          </a>
          {images.map((image, index) => (
            <div className="single-gallery" key={index}>
              <img
                src={image}
                alt={`img-${index}`}
                className="img-fluid"
                onClick={() => handleShow(image)}
                style={{ cursor: 'pointer' }}
              />
            </div>
          ))}
          {/* <div className="single-gallery">
            <img src="https://res.cloudinary.com/drt6vurtt/image/upload/v1702386642/dop/v1_sc9jyd.svg" alt="img" className='img-fluid' />
            <h6>Project Intro</h6>
            <p>20 Nov, 2023</p>
          </div>
          <div className="single-gallery">
            <img src="\assets\detail-page\dummy-gallery.svg" alt="img" className='img-fluid' />
            <h6>Project Intro</h6>
            <p>20 Nov, 2023</p>
          </div>
          <div className="single-gallery">
            <img src="\assets\detail-page\dummy-gallery.svg" alt="img" className='img-fluid' />
            <h6>Project Intro</h6>
            <p>20 Nov, 2023</p>
          </div>
          <div className="single-gallery">
            <img src="\assets\detail-page\dummy-gallery.svg" alt="img" className='img-fluid' />
            <h6>Project Intro</h6>
            <p>20 Nov, 2023</p>
          </div> */}
        </div>
      </section>

      <Modal className='image-preview' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className='p-0'>
          <img src={selectedImage} alt="Selected" className="img-fluid w-100" />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default Media
